<div class="opportunity pt-0">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="heading mb-0 mt-2">Estimate</h3>
        <div *ngIf="preview"  class="d-flex align-items-center">
            <button *ngIf="!review" class="btn base-btn estimate-btn" [disabled]="!((estimates || optionalEstimates) &&( estimates.length || optionalEstimates.length))" (click)="reviewEstimate()">Review Estimate</button>
        </div>
    </div>
    <div class="opportunity-list">
        <div *ngIf="(estimates || optionalEstimates) &&( estimates.length || optionalEstimates.length); else noDataFound" class="table-responsive scroll-hide">
            <table class="table ">
                <thead class="p-2">
                    <tr>
                        <th scope="col" style="width: 48px;">Item</th>
                        <th scope="col" style="width: 44px;">SKU</th>
                        <th scope="col" style="width: 58px;">Image</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Video</th>
                        <th scope="col">Charge Type</th>
                        <th scope="col" *ngIf="hidePrice">Price</th>
                        <th scope="col" *ngIf="hidePrice">Tax</th>
                        <th scope="col" *ngIf="hidePrice">Qty</th>
                        <th scope="col" *ngIf="hidePrice">Total</th>
                        <th scope="col">Inspection</th>
                        <th scope="col" *ngIf="hidePrice">Include</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr *ngFor="let estimate of estimates;let i=index" (click)="review && viewEstimate(estimate)">
                            <td>{{i+1}}</td>
                            <td>{{estimate.sku}}</td>
                            <td (click)="showImages(estimate.itemImage)">
                                <div *ngIf="estimate.images.length >1 ; else showSingleImage" class="more-images ">
                                <span class="rounded-circle">{{'+'+ (estimate.images.length-1)}}</span>
                                <div class="image">
                                    <img [src]="estimate.images[0].link" width="28px" height="28px" class="rounded-circle">
                                </div>
                            </div>
                            <ng-template #showSingleImage >
                                <img *ngIf="estimate.images.length else noMedia" [src]="estimate.images[0].link" width="28px" height="28px" class="rounded-circle">
                                <ng-template #noMedia>
                                    <div>---</div>
                                </ng-template>
                            </ng-template></td>
                            <td >{{estimate['itemName']}}</td>
                            <td class="description">
                                <div>{{estimate['description']||'---'}}</div>
                            </td>
                            <td (click)="showImages(estimate.itemImage)">
                                <div *ngIf="estimate.videos.length >1 ; else showSingleVideo" class="more-images ">
                                <span class="rounded-circle">{{'+'+ (estimate.videos.length-1)}}</span>
                                <div class="image">
                                    <video [src]="estimate.videos[0].link" width="28px" height="28px" class="rounded-circle"></video>
                                </div>
                            </div>
                            <ng-template #showSingleVideo >
                                <video *ngIf="estimate.videos.length else noMedia" [src]="estimate.videos[0].link" width="28px" height="28px" class="rounded-circle"></video>
                                <ng-template #noMedia>
                                    <div>---</div>
                                </ng-template>
                            </ng-template></td>
                     
                            <td>{{estimate['chargeType']}}</td>
                            <td *ngIf="hidePrice">{{'$' + estimate['price'] || '0.00'}}</td>
                            <td *ngIf="hidePrice">{{'$' + (estimate['tax'] || '0.00')}}</td>
                            <td *ngIf="hidePrice">{{estimate['quantity']}}</td>
                            <td *ngIf="hidePrice">{{'$'+estimate['total'] || '0.00'}}</td>
                            <td><span class="btn" [ngClass]="setInspectionStatus(estimate.inspectionId)">{{estimate.inspectionStatus || '---'}}</span></td>
                            <td *ngIf="hidePrice"><input class="form-check-input" type="checkbox" [disabled]="estimate?.isEssential==true" (change)="selectEstimate($event,estimate._id,estimate)" checked/></td>
                            <td>{{estimate['createdAt'] | date: 'dd MMM, yyyy'}}</td>
                            <td> 
                            <span class="btn w-100" [ngClass]="estimate['status'] == 0 ? 'orange-btn': estimate['status'] ==1 ? 'blue-btn' : estimate['status'] == 2 ? 'red-btn' : '---'"> {{estimate['status'] == 0 ? 'Pending Approval': estimate['status'] ==1 ? 'Approved' : estimate['status'] == 2 ? 'Unapproved' : '---'}}</span>
                                <!-- <span class="btn" [ngClass]="estimate.status === '0'? 'yellow-btn':'blue-btn'"> {{estimate['status'] === '0' ? 'Pending Approval': '---'}}</span> -->
                            </td>    
                        </tr>
                </tbody>
                <thead *ngIf="optionalEstimates && optionalEstimates.length" class="optional">
                    <tr class="">
                        <th colspan="14" class="">Optional Items</th>
                    </tr>
                </thead>
                <tbody *ngIf="optionalEstimates && optionalEstimates.length">
                    <tr *ngFor="let optionalEstimate of includedEstimates;let i=index" (click)="review && viewEstimate(optionalEstimate)">
                        <td >{{i+(estimates.length+1)}}</td>
                        <td>{{optionalEstimate.sku}}</td>
                        <td (click)="showImages(optionalEstimate.itemImage)">
                            <div *ngIf="optionalEstimate.images.length >1 ; else showSingleImage" class="more-images ">
                            <span class="rounded-circle">{{'+'+ (optionalEstimate.images.length-1)}}</span>
                            <div class="image">
                                {{optionalEstimate.itemImage[0].link}}
                                <img [src]="optionalEstimate.itemImage[0].link" width="28px" height="28px" class="rounded-circle">
                            </div>
                        </div>
                        <ng-template #showSingleImage >
                            <img *ngIf="optionalEstimate.itemImage.length else noMedia" [src]="optionalEstimate.itemImage[0].link" width="28px" height="28px" class="rounded-circle">
                            <ng-template #noMedia>
                                <div>---</div>
                            </ng-template>
                        </ng-template></td>
                        <td >{{optionalEstimate['itemName']}}</td>
                        <td class="description">
                            <div>{{optionalEstimate['description']}}</div>
                        </td>
                        <td (click)="showImages(optionalEstimate.itemImage)">
                            <div *ngIf="optionalEstimate.videos.length >1 ; else showSingleVideo" class="more-images ">
                            <span class="rounded-circle">{{'+'+ (optionalEstimate.videos.length-1)}}</span>
                            <div class="image">
                                <video [src]="optionalEstimate.videos[0]" width="28px" height="28px" class="rounded-circle"></video>
                            </div>
                        </div>
                        <ng-template #showSingleVideo >
                            <video *ngIf="optionalEstimate.videos.length else noMedia" [src]="optionalEstimate.videos[0]" width="28px" height="28px" class="rounded-circle"></video>
                            <ng-template #noMedia>
                                <div>---</div>
                            </ng-template>
                        </ng-template></td>
                        <!-- <td class="description">
                            <div>{{optionalEstimate['script']}}</div></td> -->
                        <td>{{optionalEstimate['chargeType']}}</td>
                        <td *ngIf="hidePrice">{{'$'+ optionalEstimate['price']||'0.00'}}</td>
                        <td *ngIf="hidePrice">{{'$' +(optionalEstimate['tax'] || '0.00')}}</td>
                        <td *ngIf="hidePrice">{{optionalEstimate['qty']}}</td>
                        <td *ngIf="hidePrice">{{'$ '+optionalEstimate['total'] || '0.00'}}</td>
                        <td><span class="btn" [ngClass]="setInspectionStatus(optionalEstimate.inspectionId)" >{{optionalEstimate.inspectionStatus || '---'}}</span></td>
                        <td *ngIf="hidePrice"><input class="form-check-input" type="checkbox"  [checked]='optionalEstimate.isInclude'   [disabled]="!review" (change)="selectEstimate($event,optionalEstimate._id,optionalEstimate)"/></td>
                        <td>{{optionalEstimate['createdAt'].replace(' ','T')| date: dateFormat}}</td>
                        <td> 
                            <span class="btn w-100" [ngClass]="optionalEstimate['status'] == 0 ? 'orange-btn': optionalEstimate['status'] == 1 ? 'blue-btn' : optionalEstimate['status'] == 2 ? 'red-btn' : '---'">{{optionalEstimate['status'] == 0 ? 'Pending Approval': optionalEstimate['status'] == 1 ? 'Approved' : optionalEstimate['status'] == 2 ? 'Unapproved' : '---'}}</span>
                        </td>    
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!hidePrice && (review && (estimates || optionalEstimates) &&( estimates.length || optionalEstimates.length))" class="w-100">
            <hr class="dashed-line"/>
            <div class="py-3">
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="amount-heading pb-0 mb-0">SubTotal</h3>
                    <!-- <div class="d-flex align-items-center">
                        <label class="label px-2">Amount</label>
                        <span class="totalAmount" *ngIf="!review">{{'$'+ calculateAmount()}}</span>
                        <span class="totalAmount" *ngIf="review">{{'$'+ totalAmount}}</span>
                    </div> -->
                    <span class="amount-heading"*ngIf="!review">{{'$'+ calculateAmount('total')}}</span>
                    <span class="amount-heading" *ngIf="review">{{'$'+ (totalAmount - taxAmount).toFixed(2)}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="amount-breakup pb-0 mb-0">Tax</h3>
                    <!-- <span class="amount-breakup"*ngIf="!review">{{'$'+ calculateAmount()}}</span> -->
                    <span class="amount-breakup" *ngIf="review">{{'$'+ taxAmount}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="amount-breakup pb-0 mb-0">Total</h3>
                    <!-- <span class="amount-breakup"*ngIf="!review">{{'$'+ calculateAmount()}}</span> -->
                    <span class="amount-breakup" *ngIf="review">{{'$'+ totalAmount}}</span>
                </div>
            </div>
        </div>
        <ng-template #noDataFound>
            <div class="no-data">No Estimate Found.</div>
        </ng-template>
    </div>
    <button *ngIf="hidePrice" [disabled]="!approveEstimateIds.length" class="btn d-flex base-btn estimate-btn approve-btn" (click)="approveEstimate()">Approve All Selected Item</button>
</div>

