import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { API_ROUTES } from 'src/app/core/_constants/api-route.constant';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { RouteConstant } from 'src/app/core/_constants/route.constant';
import { CommonService } from 'src/app/core/_services/common.service';
import { MainService } from 'src/app/core/_services/main.service';
import { LocalStorageProvider } from 'src/app/core/_services/storage.service';
import { CollectSignatureComponent } from '../../dialogs/collect-signature/collect-signature.component';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {
  public dateFormat = AppConst.DATE_FORMAT
  @Output() changeEstimateDetails = new EventEmitter();
  @Input() preview: any;
  @Input() estimates: any;
  @Input() optionalEstimates: any;
  @Input() review: any;
  @Input() hidePrice: any;
  public includedEstimates: any;
  approveEstimateIds: any = []
  totalAmount: any;
  taxAmount: any;
  constructor(private router: Router,
    private storageService: LocalStorageProvider,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private loader: NgxUiLoaderService,
    private toastr: ToastrService,
    private service: MainService
  ) { }

  ngOnInit(): void {
    this.getDefaultStatus();
  }
  ngOnChanges() {
    this.setInitiallySelectedEstimateIds();
    this.totalAmount = this.calculateAmount('total');
    this.taxAmount = this.calculateAmount('tax')

    if (this.optionalEstimates) {
      this.includedEstimates = this.optionalEstimates.filter((x: any) => {
        return x.isInclude;
      })
    }
  }
  setInitiallySelectedEstimateIds() {
    if (this.review) {
      this.estimates.map((estimate: any) => {
        this.approveEstimateIds.push(estimate._id);
      });
    }
  }
  inspectionBtn: any = []
  getDefaultStatus() {
    this.service.get({}, `${API_ROUTES.OPPORTUNITY.getInspectionBtnStatus}`).pipe().subscribe(response => {
      console.log(response)
      if (response.status) {
        let initialData = response.data?.list;
        this.inspectionBtn = response.data?.list;
        this.estimates?.forEach((estimate) => {
          const foundEstimate = initialData.find((initial) => initial._id === estimate.inspectionId);
          if (foundEstimate) {
            estimate.inspectionStatus = foundEstimate.fieldName;
          }
        });
      }
    })
  }

  setInspectionStatus(id: number): string {
    console.log(id);
    const foundBtn = this.inspectionBtn?.find(ele => ele?._id === id);
    console.log(foundBtn, 'nwknkjwn')
    if (foundBtn) {
      // Apply class based on originalName
      switch (foundBtn.originalName) {
        case 'Fail':
          return 'red-btn';
        case 'Pass':
          return 'pass-status';
        case 'N/A':
          return 'grey-btn';
        default:
          return '';
      }
    } else {
      return ''; // Default class if no button is found
    }
  }

  calculateAmount(key: any) {
    let total = 0.00;
    if (this.estimates) {
      this.estimates.map((order: any) => {
        total = total + parseFloat(order[key]);
      });
      return total.toFixed(2);
    }
    return 0.00;
  }

  showImages(images: any) {
    this.commonService.openImageSliderModal(images)
  }

  // setInspectionStatus(status:any){
  //   switch(status){
  //     case 'Pass':
  //       return 'green-btn';
  //     case 'Fail':
  //       return 'red-btn';
  //     default:
  //       return 'grey-btn';
  //   }
  // }
  // setInspectionStatus(status: any) {
  //   if(status){
  //     return 'green-btn';
  //   }
  //   else{
  //     return null;
  //   }
  // }
  reviewEstimate() {
    this.storageService.setItem('Estimates', this.estimates);
    this.storageService.setItem('OptionalEstimates', this.optionalEstimates);
    this.router.navigate([`${RouteConstant.estimateDetail}`], { queryParamsHandling: "merge", relativeTo: this.activatedRoute })
  }
  approveEstimate() {
    this.commonService.collectSignature();
    this.commonService.confirmed().subscribe(data => {
      if (data) {
        this.approve(data);
      }
    })
  }
  approve(signature: any) {
    const allEstimates = [...this.estimates, ...this.optionalEstimates];
    const estimateIds = allEstimates
      .filter((estimate: any) => estimate.isInclude)
      .map((estimate: any) => estimate._id);
    let data = {
      estimateIds: estimateIds,
      status: 'Approved',
      signature: signature,
      opportunityId: this.activatedRoute.snapshot.queryParamMap.get('oppId') || this.activatedRoute.snapshot.queryParamMap.get('jobId')
    }
    this.loader.start();
    this.service.post(data, `${API_ROUTES.OPPORTUNITY.approveEstimate}`).pipe().subscribe(response => {
      if (response.status) {
        if (this.router.url.includes('opportunity-detail/review-estimate')) {
          this.router.navigate([`/dashboard/jobs/job-detail`], { queryParams: { jobId: data.opportunityId } })
        } else {
          this.commonService.goBack();
        }
        this.loader.stop();
      } else {
        this.loader.stop();
        this.toastr.error(response.msg);
      }
    })
  }
  selectEstimate(e: any, id: any, estimate: any) {
    e.target.checked ? this.addSelectedEstimateId(id, estimate) : this.removeSelectedEstimateId(id, estimate)
  }
  addSelectedEstimateId(id: any, estimate: any) {
    this.totalAmount = (parseFloat(this.totalAmount) + parseFloat(estimate.total)).toFixed(2);
    this.taxAmount = (parseFloat(this.taxAmount) + parseFloat(estimate.tax)).toFixed(2);
    this.approveEstimateIds.push(id);
  }
  removeSelectedEstimateId(id: any, estimate: any) {
    this.totalAmount = (parseFloat(this.totalAmount) - parseInt(estimate.total)).toFixed(2);
    this.taxAmount = (parseFloat(this.taxAmount) - parseFloat(estimate?.tax)).toFixed(2);
    const updatedApproveEstimateIdsArray = this.approveEstimateIds.filter((e: any) => e !== id);
    this.approveEstimateIds = updatedApproveEstimateIdsArray;
  }
  viewEstimate(estimate: any) {
    this.commonService.setActiveEstimate(estimate);
  }

  // checkIncludeValue(id: string) {
  //   console.log("🚀 ~ EstimateComponent ~ checkIncludeValue ~ approveEstimateIds:", id , this.approveEstimateIds)
  //   return this.approveEstimateIds.includes(id)
  // }
}
