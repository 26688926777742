import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConst } from 'src/app/core/_constants/app.constant';
import { PaymentsComponent } from '../../dialogs/payments/payments.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public dateFormat=AppConst.DATE_FORMAT;
  @Input() totalAmount: number = 0;
  @Input() totalAmountPaid: number = 0;
  @Input() oppDetails: any = [];
  @Input() preview:any;
  @Input() payments: any| [];
  @Input() openPopup: any| [];
  @Input() serviceData: any| [];
  balanceRemaining: number;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes && changes['totalAmount'] && changes['totalAmount'].currentValue){
      this.balanceRemaining = this.totalAmount - this.totalAmountPaid;
    }  
    if(changes && changes['openPopup'] && changes['openPopup'].currentValue){
      console.log(this.openPopup)
      return;
      if(this.openPopup == 'true'){
        this.openPaymentPopUp()
      }
    }  
  }

  formatValue(val: any){
    if(val){
      val = Number(val).toFixed(2)
      return '$' + val;
    }
    else{
      return '$0'
    }
  }

  openPaymentPopUp(){
    console.log('------')
    const dialogRef = this.dialog.open(PaymentsComponent, {
      maxHeight: '100vh',
      width: '700px',
      maxWidth: '100%',
      // panelClass: 'custom-modalbox',
      disableClose: true,
      data: {
        heading: 'Choose mode of payment',
        totalAmount: this.totalAmount ,
        balanceRemaining: this.totalAmount - Number(this.totalAmountPaid),
        payment: Number(this.totalAmountPaid),
        oppId: this.oppDetails._id,
        oppDetails: this.oppDetails
      }
    })
    dialogRef.afterClosed().subscribe((confirm: any) => {
      if (confirm !== undefined && confirm !== null && confirm) {
        // this.getOppDetails(this.oppId)
      }
    });
  }

}
