export const RouteConstant = {
    Dashboard:'dashboard',
    Login:'login',
    OtpVerification:'otp-verification',
    Profile:'profile',
    editProfile:'edit-profile',
    Messages:'messages',
    Jobs:'jobs',
    Payments:'payments',
    Invoices:'invoices',
    Events:'events',
    Tasks:'tasks',
    Opportunities:'opportunities',
    changePassword:'change-password',
    Auth:'auth',
    opportunityPreview:'opportunity-preview',
    addEstimate:'add-estimate',
    opportunityDetail:'opportunity-detail',
    Notification: 'notification',
    opportunityWorkOrder:'opportunity-workorder',
    JobDetail:'job-detail',
    estimateDetail:'review-estimate',
    serviceProviders: `service-providers`,
    WorkOrderDetail: `review-workorder`,
    verifyEmail: `verifymail`
}