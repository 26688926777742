<div class="opportunity pt-0">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="heading mb-0 mt-2">Payments</h3>
        <div *ngIf="preview"  class="d-flex align-items-center">
            <button class="btn base-btn estimate-btn" [disabled]='!balanceRemaining || true' (click)="openPaymentPopUp()">
                <!-- <svg-icon src="assets/images/add_circle_outline.svg"></svg-icon> -->
                <span> Add Payment</span>
            </button>
        </div>
    </div>
    <div class="opportunity-list">
        <div  *ngIf="payments && payments.length; else noDataFound" class="table-responsive scroll-hide">
            <table class="table ">
                <thead class="p-2">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Service</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Method</th>
                        <th scope="col">Misc. Notes</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let payment of payments">
                        <td> {{payment?.createdAt | date: 'dd MMM, yyyy'}}</td>
                        <td> {{serviceData ? (serviceData.itemName | titlecase) : 'NA'}}</td>
                        <td> {{payment?.isRefund ? "Refund - " : ""}} {{payment?.paymentType | titlecase}} </td>
                        <td> {{payment?.method | titlecase}} </td>
                        <td class="description">
                            <ng-container *ngIf="payment?.paymentType !== 'check'; else elseTemplateCheck">
                                {{payment?.notes || '---'}}
                            </ng-container>
                            <ng-template #elseTemplateCheck>
                                <span class="detail" [matMenuTriggerFor]="menu">Check Details</span> 
                                <svg-icon src="../../../assets/images/downArrow.svg" [matMenuTriggerFor]="menu"></svg-icon> 
                                <mat-menu #menu="matMenu">
                                    <div class="data">
                                        <label for="">Payee Name</label>
                                        <div class="value">{{payment?.payeeDetailForCheque?.payeeName}}</div>
                                    </div>
                                    <div class="data">
                                        <label for="">Bank Name</label>
                                        <div class="value">{{payment?.payeeDetailForCheque?.bankName}}</div>
                                    </div>
                                    <div class="data">
                                        <label for="">Check Number</label>
                                        <div class="value">{{payment?.payeeDetailForCheque?.chequeNumber}}</div>
                                    </div>
                                    <div class="data">
                                        <label for="">Date on the check</label>
                                        <div class="value">{{payment?.payeeDetailForCheque?.date | date: 'd, MMM YYYY'}}</div>
                                    </div>
                                    <div class="data">
                                        <label for="">Misc Notes</label>
                                        <div class="value">{{payment?.notes}}</div>
                                    </div>
                              </mat-menu>
                            </ng-template>
                        </td>
                        <td> {{formatValue(payment?.amount)}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #noDataFound>
            <div class="no-data">No Payment Found.</div>
        </ng-template>
    </div>
</div>
